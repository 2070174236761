export type ClientMonetizationConfiguration = {
  currency: Currency
  defaultValue: number
}

type ClientTrackingConfiguration = {
  authorizedDomains: string[]
}

type ClientMapConfiguration = {
  id: string
}

export type ClientConfiguration = {
  name: string
  monetization: ClientMonetizationConfiguration
  tracking: ClientTrackingConfiguration
  map: ClientMapConfiguration
}

export type LatLng = {
  lat: number
  lng: number
}

// GEOJSON
export type divisionIntention = {
  divisionName: string
  divisionId: string
  totalCount: number
  totalValue: number
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD',
}
