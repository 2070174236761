import { ref } from 'vue'
import { Drawer } from '../@types/Overlay'
import { divisionIntention } from '../@types/Opportunity'
import { Properties } from '../features/layer/@types/layer'
import Layer from '../features/layer/layer.entities'
import { useLayer } from './useLayer.composable'
import { Nullable } from '@/features/@types/Nullable'

const initDrawer = () => ({ show: false, color: '', intention: null })
const drawer = ref<Drawer>(initDrawer())

export function useDrawer() {
  const { getLayerStyle, getLayerData } = useLayer()

  function closeDrawer(mapLayer?: google.maps.Data) {
    if (mapLayer) mapLayer.revertStyle()

    drawer.value = initDrawer()
  }

  function openDrawer() {
    drawer.value.show = true
  }

  function getDrawerProperties(geojsonId: string, layer: Layer) {
    const layerData = getLayerData(geojsonId, layer.intentions)
    const layerStyle = getLayerStyle(layer.style.rules, { totalCount: layerData?.totalCount ?? 0 })

    return { layerData, layerStyle }
  }

  function setDrawerProperties(data: Nullable<divisionIntention>, style: Properties | undefined) {
    if (data) drawer.value.intention = data
    drawer.value.color = style?.fillColor || ''
  }

  return {
    drawer,
    openDrawer,
    closeDrawer,
    getDrawerProperties,
    setDrawerProperties,
  }
}
