import { DivisionKey } from '../../features/layer/@types/layer'
import { HttpRepository } from '../repositories/http.repository'
import { DivisionSearch, DivisionsSearchDto } from '../@types/search'
import { COUNTRY_CODE } from '../../@types/LocaleCode'

type DivisionsSearch = {
  key: DivisionKey,
  items: DivisionSearch[]
}

class SearchService {
  #_repository: HttpRepository
  #URI: string

  constructor(repository: HttpRepository, country: COUNTRY_CODE) {
    this.#_repository = repository
    this.#URI = `/countries/${country}/divisions/search`
  }

  async getSuggestions(input: string) {
    const params = {
      limit: 3,
      query: input
    }

    const divisions = await this.#_repository.POST<DivisionsSearchDto>(this.#URI, params)
    
    const formattedSuggestions = Object.entries(divisions).map(division => {
      return {
        key: division[0],
        items: division[1]
      } as DivisionsSearch
    })
    
    return formattedSuggestions
  }
}

export default SearchService
