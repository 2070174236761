import { divisionIntention } from '../../@types/Opportunity'
import { ConditionalStyle, ConditionalStyleProperties, Levels, Style } from '../../features/layer/@types/layer'
import LayerGmapProvider from './layerGmap.provider'

export const LEVEL_KEY = 'level'

class LayerMapService {
  #adapter: LayerGmapProvider
  #layers: Map<Levels, google.maps.Data> = new Map()

  constructor(provider: LayerGmapProvider) {
    this.#adapter = provider
  }

  createLayer(level: Levels) {
    const mapLayer = this.#adapter.createLayer()
    mapLayer.set(LEVEL_KEY, level)
    return mapLayer
  }

  saveLayer(level: Levels, layer: google.maps.Data) {
    this.#layers.set(level, layer)
    return layer
  }

  getLayer(level: Levels) {
    return this.#layers.get(level)
  }

  getLayers() {
    return this.#layers
  }

  setLayerOnMap(layer: google.maps.Data) {
    this.#adapter.setLayerOnMap(layer)
  }

  hideLayerOnMap() {
    this.#adapter.hideLayerOnMap(this.#layers)
  }

  setLayerStyle(layer: google.maps.Data, style: Style, intentions: divisionIntention[]) {
    this.#adapter.setLayerStyle(layer, style, intentions)
  }

  static getLayerStyleBasedOnRules(rules: ConditionalStyle[], properties: ConditionalStyleProperties) {
    return LayerGmapProvider.getGeojsonStylePerRules(rules, properties)
  }

  handleMouseOver(layer: google.maps.Data, callback: Function) {
    this.#adapter.setMouseOverListener(layer, callback)
  }

  handleMouseMove(layer: google.maps.Data, callback: Function) {
    this.#adapter.setMouseMoveListener(layer, callback)
  }

  handleMouseOut(layer: google.maps.Data, callback: Function) {
    this.#adapter.setMouseOutListener(layer, callback)
  }

  handleMouseClick(layer: google.maps.Data, callback: Function) {
    this.#adapter.setMouseClickListener(layer, callback)
  }

  clearListener() {
    for (const [_, layer] of this.#layers) {
      google.maps.event.clearInstanceListeners(layer)
    }
  }

  clearLayersListener() {
    this.clearListener()
    this.#layers = new Map()
  }
}

export default LayerMapService
