import { ref } from 'vue'
import GmapProvider from '../../features/map/gmap.provider'

class MapService {
  #SCRIPT_ID = 'SCRIPT-MAP'
  #adapter: GmapProvider
  ELEMENT_ID = 'ELEMENT-MAP'
  isLoading = ref(false)

  constructor(provider: GmapProvider) {
    this.#adapter = provider
  }

  setLoading(isLoading: boolean) {
    this.isLoading.value = isLoading
  }

  createScript(options: google.maps.MapOptions): Promise<google.maps.Map | unknown> {
    return this.#adapter.createScript(this.#SCRIPT_ID, this.ELEMENT_ID, options)
  }

  setViewOnBounds(bounds: google.maps.LatLngBounds) {
    this.#adapter.fitBounds(bounds)
  }

  get mapZoom() {
    return this.#adapter.getMapZoom()
  }

  setZoom(zoom: number) {
    this.#adapter.setZoom(zoom)
  }

  zoomIn() {
    this.#adapter.zoomIn()
  }

  zoomOut() {
    this.#adapter.zoomOut()
  }

  handleZoomChange(callback: Function) {
    this.#adapter.setZoomChangedListener(callback)
  }

  clearMapListener() {
    this.#adapter.clearMapAndListener()
  }
}

export default MapService
