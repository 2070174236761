import { COUNTRY_CODE } from '../../@types/LocaleCode'
import { LatLng, ClientMonetizationConfiguration } from '../../@types/Opportunity'
import { ClientLayer } from '../layer/@types/layer'

type ClientPayload = {
  zoom: number
  center: LatLng
  country: COUNTRY_CODE
  mapId: string
  layers: ClientLayer[]
  monetization: ClientMonetizationConfiguration
}

class Client {
  zoom: number
  center: LatLng
  country: COUNTRY_CODE
  mapId: string
  layers: ClientLayer[]
  monetization: ClientMonetizationConfiguration

  constructor(
    zoom: number,
    center: LatLng,
    country: COUNTRY_CODE,
    mapId: string,
    layers: ClientLayer[],
    monetization: ClientMonetizationConfiguration
  ) {
    this.zoom = zoom
    this.center = center
    this.country = country
    this.mapId = mapId
    this.layers = layers
    this.monetization = monetization
  }
}

export default Client

export function createClient({ zoom, center, country, mapId, layers, monetization }: ClientPayload) {
  return new Client(zoom, center, country, mapId, layers, monetization)
}
