import { FieldConditionOperator } from '../opportunity/@types/Operator'

class MockAdapterClass {
  static async GET<T>(params: string): Promise<T> {
    if (params === '') {
      const response = {
        country: 'US',
        layers: [
          {
            breakpoint: 7, level: 1, type: 'level1',
            style: {
              base: { fillColor: '#2e2d2d', fillOpacity: 0.7, strokeOpacity: 1, strokeWeight: 1, strokeColor: '#2e2d2d' },
              rules: [
                {
                  label: {
                    en: "No intention"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.EQUALS,
                  comparator: '0',
                  style: {
                    fillColor: '#2e2d2d',
                    strokeColor: '#2e2d2d',
                  }
                },
                {
                  label: {
                    en: "Less than 300 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '300',
                  style: {
                    fillColor: '#0B6D9A',
                    strokeColor: '#064F6F',
                  }
                },
                {
                  label: {
                    en: "Less than 500 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '500',
                  style: {
                    fillColor: '#8BC8C8',
                    strokeColor: '#6BC7C7',
                  }
                },
                {
                  label: {
                    en: "Less than 800 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '800',
                  style: {
                    fillColor: '#FFE390',
                    strokeColor: '#FFDE80',
                  }
                },
                {
                  label: {
                    en: "Less than 1000 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '1000',
                  style: {
                    fillColor: '#F5B72E',
                    strokeColor: '#F5B72E',
                  }
                },
                {
                  label: {
                    en: "Less or equal to 2000 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR_EQUALS,
                  comparator: '2000',
                  style: {
                    fillColor: '#FF6155',
                    strokeColor: '#FF6155',
                  }
                },
                {
                  label: {
                    en: "More than 2000 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.SUPERIOR,
                  comparator: '2000',
                  style: {
                    fillColor: '#C01100',
                    strokeColor: '#C01100',
                  }
                }
              ]
            }
          },
          {
            level: 2,
            type: 'level2',
            style: {
              base: { fillColor: '#2e2d2d', fillOpacity: 0.7, strokeOpacity: 1, strokeWeight: 1, strokeColor: '#2e2d2d' },
              rules: [
                {
                  label: {
                    en: "No intention"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.EQUALS,
                  comparator: '0',
                  style: {
                      fillColor: '#2e2d2d',
                      strokeColor: '#2e2d2d',
                  }
                },
                {
                  label: {
                    en: "Less than 50 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '50',
                  style: {
                      fillColor: '#0B6D9A',
                      strokeColor: '#064F6F',
                  }
                },
                {
                  label: {
                    en: "Less than 100 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '100',
                  style: {
                      fillColor: '#8BC8C8',
                      strokeColor: '#6BC7C7',
                  }
                },
                {
                  label: {
                    en: "Less than 200 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '200',
                  style: {
                      fillColor: '#FFE390',
                      strokeColor: '#FFE390',
                  }
                },
                {
                  label: {
                    en: "Less than 300 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR,
                  comparator: '300',
                  style: {
                      fillColor: '#F5B72E',
                      strokeColor: '#F5B72E',
                  }
                },
                {
                  label: {
                    en: "Less than 500 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.INFERIOR_EQUALS,
                  comparator: '500',
                  style: {
                      fillColor: '#FF6155',
                      strokeColor: '#FF6155',
                  }
                },
                {
                  label: {
                    en: "More than 500 intentions"
                  },
                  property: 'totalCount',
                  operator: FieldConditionOperator.SUPERIOR,
                  comparator: '500',
                  style: {
                      fillColor: '#C01100',
                      strokeColor: '#C01100',
                  }
                }
              ]
            }
          },
          // { breakpoint: 10, uri: '/postalCode/us/opportunities' } To illustrate breakpoint possibility
        ]
      }

      return response as T
    }

    return null as T
  }
}

export default MockAdapterClass
